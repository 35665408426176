<template>
  <div>
    <div class="search">
      <div class="title">行业列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <!-- <el-form-item label="昵称:">
            <el-input v-model="params.nickName"></el-input>
          </el-form-item>
          <el-form-item label="手机号:">
            <el-input v-model="params.phone"></el-input>
          </el-form-item>
          <el-form-item label="时间:">
            <el-date-picker
              :editable="false"
              :picker-options="pickerOptions0"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="date"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item> -->
        <el-form-item>
          <!-- <el-button type="primary" @click="getList(1)">查询</el-button> -->
          <el-button type="primary" @click="add">添加</el-button>
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <!-- <el-table-column type="index" width="50" align="center"> </el-table-column> -->
      <el-table-column width="100" label="排序" align="center">
        <template slot-scope="{ row }">
          <div>
            <el-input v-model="row.dept" type="number" @change="setSort(row)"></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="industry_name" label="行业名称" align="center">
      </el-table-column>
      <!-- <el-table-column label="头像 " width="180" align="center">
          <template slot-scope="scope">
            <el-image
              style="width: 70px; height: 70px"
              :src="scope.row.headImg"
              :preview-src-list="[scope.row.headImg]"
            >
            </el-image>
          </template>
        </el-table-column> -->

      <el-table-column prop="addTimeStr" label="添加时间" width="200" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="remove(scope.row)"
            >删除</el-button
          >
          <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
                <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
              <el-button type="primary" @click="allOpe">批量操作</el-button>
           </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="行业名称:" prop="industry_name">
          <el-input v-model="addList.industry_name"></el-input>
        </el-form-item>
        <!-- <el-form-item label="分类图片:">
            <el-input v-model="addList.cateName"></el-input>
          </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getIndustryList,
  industryListAdd,
  industryListUpdata,
  industryListDel,
} from "../../api/vip.js";
export default {
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        // nickName: "",
        // phone: "",
        // beginTime: "",
        // endTime: "",
      },
      addList: {
        industry_id: null,
        industry_name: "",
        industry_father_id: 0,
      },
      addRules: {
        industry_name: [
          { required: true, message: "请输入行业名称", trigger: "blur" },
        ],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async setSort(row) {
      // 修改排序
      const { data } = await industryListUpdata({
        dept: row.dept,
        industry_id: row.industry_id,
      });
      if (data.code == 0) {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.getList();
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
      }
    },
    rowKey(row) {
      return row.userId;
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await getIndustryList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
    add() {
      this.dialogVisible = true;
      this.addList.industry_name = "";
      this.addList.industry_id = null;
    },
    edit(row) {
      this.dialogVisible = true;
      this.addList.industry_name = row.industry_name;

      this.addList.industry_id = row.industry_id;
    },
    remove(row) {
      // 是否确定删除
      this.$confirm("确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //   this.addList.prodFee = Number(this.addList.prodFee);
          industryListDel({ industry_id: row.industry_id }).then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });

              this.getList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {});
    },
    submit() {
      this.$refs.addRuleFormRef.validate(async (valid) => {
        if (valid) {
          let res = null;
          if (this.addList.industry_id) {
            res = await industryListUpdata(this.addList);
          } else {
            res = await industryListAdd(this.addList);
          }
          const { data } = res;
          if (data.code == 0) {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
